<template>
<div>
  <div v-if="mainNotificationsList.length > 0">
    <b-form-group>
      <b-card no-body class="mb-1">
        <b-card-body class="d-flex justify-content-between align-items-center p-1">
          <!-- <b-form-checkbox :value="selected">تحديد الكل</b-form-checkbox> -->
          <!-- this button should hide and show if checkbox is checked -->
          <b-button
            size="sm"
            variant="flat-secondary"
            class="btn-icon rounded-circle mr-2"
            :disabled="selected.length == 0"
            @click="RemoveNotifications()"
          >
            <unicon name="trash-alt" width="18"></unicon>
          </b-button>
        </b-card-body>
      </b-card>
      <b-form-checkbox-group v-model="selected">
        <app-collapse accordion :type="collapseType">
          <b-card
            no-body
            class="mb-1"
            v-for="(notification, index) in notifiActivePage"
            :key="index"
          >
            <b-card-header class="justify-content-end pb-0 pr-3">
              <b-badge
                pill
                variant="light-primary"
                class="mr-1"
                v-if="notification.notificationType == 0"
                >تطبيق الطلاب</b-badge
              >
              <b-badge pill class="mr-1" variant="light-warning" v-if="notification.notificationType == 1"
                >تطبيق نقاط البيع</b-badge
              >
              <strong>
                {{ new Date(notification.date).toISOString().substr(0, 10) }}
              </strong>
            </b-card-header>
            <b-card-body class="d-flex">
              <b-form-checkbox class="mt-2" :value="notification.id"></b-form-checkbox>
              <app-collapse-item class="w-100" :title="notification.title">
                <hr class="mt-0" />
                {{ notification.body }}
              </app-collapse-item>
            </b-card-body>
          </b-card>
        </app-collapse>
      </b-form-checkbox-group>
    </b-form-group>

    <!-- <div>
      Selected: <strong>{{ selected }}</strong>
    </div> -->
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        :value="notifiFirstPage"
        :total-rows="mainNotificationsList.length"
        :per-page="notifiPageLength"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value) => notifiPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
      </b-pagination>
    </b-col>
  </div>
  <div v-else class="text-center mt-2">
    <h4>لا يوجد اشعارات</h4>
  </div>
    <notifiDialog />
</div>
</template>

<script>
import {
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BPagination,
  BCol,
  BBadge,
} from "bootstrap-vue";
import notifiDialog from "./components/notifiDialog";
import store from "@/store";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BButton,
    BPagination,
    BBadge,
    notifiDialog
  },
  data() {
    return {
      collapseType: "default",
      pageLength: 10,
      selected: [], // Must be an array reference!
    };
  },
  created() {
    this.fetchNotifications();
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "إشعار جديد",
      fetchingFunction: this.toggleNotifiDialog,
      placeHolder: "ابحث عن إشعار محدد",
      value: "",
      filterFunc: this.filterNotifications
    });
  },
  computed: {
    ...mapGetters([
      "mainNotificationsList",
      "notifiPageLength",
      "notifiActivePage",
      "notifiFirstPage",
    ]),
  },
  methods: {
    ...mapActions(["toggleNotifiDialog", "fetchNotifications", "notifiPagination",
                    "deleteNotifications", "filterNotifications"]),
    RemoveNotifications(){
      this.deleteNotifications(this.selected)
    }
  },
};
</script>
