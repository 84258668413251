<template>
  <div>
    <validation-observer ref="validateNotifi">
      <b-form>
        <b-modal
          dialog-class="ad-modal-dialog"
          content-class="ad-modal-content"
          v-model="notifiModal"
          scrollable
          size="sm"
          ref="notifiModal"
          @hide="toggleNotifiDialog(false)"
        >
          <template #modal-title>
            <strong>إضافة إشعار</strong>
          </template>

          <b-form-group label="إرسال إلى" label-for="sendTo">
            <div id="notifiSwitch" class="d-flex justify-content-center align-items-baseline">
              <label class="mx-1">تطبيق الطالب</label>
              <b-form-checkbox
                id="sendTo"
                class="custom-control-warning"
                v-model="ToPos"
                switch
              >
               تطبيق نقاط البيع
              </b-form-checkbox>
            </div>
          </b-form-group>

          <b-form-group label="العنوان الرئيسي" label-for="notifiName">
            <validation-provider
              #default="{ errors }"
              name="العنوان الرئيسي"
              rules="required"
            >
              <b-form-input
                id="notifiName"
                v-model="notificationForm.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="النص" label-for="body">
            <validation-provider #default="{ errors }" name="النص" rules="required">
              <b-form-textarea
                id="body"
                v-model="notificationForm.body"
                rows="4"
                :state="errors.length > 1 ? false : null"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <template #modal-footer>
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitSetNotifi()"
            >
              إضافة
            </b-button>
            <b-button
              @click="toggleNotifiDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>
.ad-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.ad-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
#notifiSwitch .custom-control-label::before {
     border-color:#7367f0 ;
    background-color: #7367f0 ;
}
[dir] #notifiSwitch .custom-control-warning .custom-control-input:checked ~ .custom-control-label::before, [dir] .custom-control-warning .custom-control-input:active ~ .custom-control-label::before {
    border-color: #ff9f43;
    background-color: #ff9f43;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    notifiModal: false,
    required,
    ToPos: false,
  }),
  mounted() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isNotifiDialog", "notificationForm"]),
  },
  methods: {
    ...mapActions(["toggleNotifiDialog", "setNotification"]),
    SubmitSetNotifi() {
      this.$refs.validateNotifi.validate().then((success) => {
        if (success) {
          this.notificationForm.notificationType = this.ToPos ? 1 : 0; //switch between pos(1) and student(0)
          this.setNotification(this.notificationForm);
          this.$refs.notifiModal.hide();
          this.$bvToast.toast(`تم إضافة الإشعار بنجاح`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  watch: {
    isNotifiDialog(newVal) {
      this.notifiModal = newVal;
    },
  },
};
</script>
